import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/loading-dots/LoadingDots.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/loading-dots" storybook="https://workday.github.io/canvas-kit/?path=/story/components-indicators-loading-dots--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=30877-34303&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/83f698d6e8a59f98b4e35a155f8d4fc6/536c7/loading-dots-anatomy.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "22.972972972972975%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Loading Dots with annotation markers.",
              "title": "Image of a Loading Dots with annotation markers.",
              "src": "/static/83f698d6e8a59f98b4e35a155f8d4fc6/50383/loading-dots-anatomy.png",
              "srcSet": ["/static/83f698d6e8a59f98b4e35a155f8d4fc6/1efb2/loading-dots-anatomy.png 370w", "/static/83f698d6e8a59f98b4e35a155f8d4fc6/50383/loading-dots-anatomy.png 740w", "/static/83f698d6e8a59f98b4e35a155f8d4fc6/536c7/loading-dots-anatomy.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Default Loading Dots:`}</strong>{` Ideal for use on white backgrounds`}</li>
        <li parentName="ol"><strong parentName="li">{`With Circle Variation:`}</strong>{` For use on gray, dark, or image-based backgrounds.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Use Loading Dots to identify when a specific area of the page is loading (i.e. the content within
a card).`}</li>
        <li parentName="ul">{`Motion should be applied to the Loading Dots to reinforce that the content on the page is loading
behind the scenes.`}</li>
        <li parentName="ul">{`Avoid increasing the size of the Loading Dots to maintain consistency with other indicators
accross the application.`}</li>
        <li parentName="ul">{`Always center the Loading Dots and position it close to where you want the user's attention to be
once loading is complete.`}</li>
        <li parentName="ul">{`Use for specific elements on a page that are still loading while other content has already loaded.`}</li>
        <li parentName="ul">{`If only a portion of the interface is displaying new content or being updated, place the Loading
Dots in that specific part of the interface.`}</li>
        <li parentName="ul">{`In immediate response to an executed action when there is excessive time before the expected
results occur.`}</li>
        <li parentName="ul">{`Ideally used on a white background.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use on page elements where the visual layout/format of the content being loaded is unknown.`}</li>
        <li parentName="ul">{`Use to indicate processing, or that change will occur on the page (rather than loading UI
elements).`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Consider using a `}<a parentName="li" {...{
            "href": "/components/indicators/skeleton"
          }}>{`Skeleton`}</a>{` if the visual layout/format of the
content being loaded is known ahead of time (preferred).`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`Loading Dots should announce to users using a screen reader that a page or a portion of the page
is currently being loaded.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`Loading Dots typically should not appear long enough to require any content.`}</li>
        <li parentName="ul">{`In rare use cases it may be helpful to support the Loading Dots with a brief statement describing
specifically what is currently loading.`}</li>
        <li parentName="ul">{`It's recommended to keep loading UI descriptions a short, single line sentence as the user may
have very little time to read it.`}</li>
      </ul>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      